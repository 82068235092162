var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wallet-balance-wrapper" }, [
    _c("p", { staticClass: "wallet-title" }, [
      _vm._v(_vm._s(_vm.getCoinNameFromSymbol(_vm.coin)) + " Wallet"),
    ]),
    _vm.metaInfo
      ? _c(
          "a",
          {
            staticClass: "wallet-address",
            attrs: { href: _vm.metaInfo.explorer },
          },
          [_vm._v(_vm._s(_vm.address))]
        )
      : _vm._e(),
    _c("div", { staticClass: "balance-inner" }, [
      _vm.metaInfo
        ? _c("img", {
            staticClass: "wallet-qr",
            attrs: { src: _vm.metaInfo.qrCode, alt: "QR Code" },
          })
        : _vm._e(),
      _vm.balances
        ? _c("div", { staticClass: "balances-section" }, [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.makeBalanceTooltip(_vm.balances),
                    expression: "makeBalanceTooltip(balances)",
                  },
                ],
                staticClass: "main-balance",
              },
              [_vm._v(_vm._s(_vm.balances.current))]
            ),
            _c("div", { staticClass: "balance-info" }, [
              _c("div", { staticClass: "balance-info-row" }, [
                _c("span", { staticClass: "label" }, [_vm._v("Total In")]),
                _c("span", { staticClass: "amount" }, [
                  _vm._v("+ " + _vm._s(_vm.balances.totalReceived)),
                ]),
              ]),
              _c("div", { staticClass: "balance-info-row" }, [
                _c("span", { staticClass: "label" }, [_vm._v("Total Out:")]),
                _c("span", { staticClass: "amount" }, [
                  _vm._v("- " + _vm._s(_vm.balances.totalSent)),
                ]),
              ]),
              _c("div", { staticClass: "balance-info-row" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("Last Activity:"),
                ]),
                _c("span", { staticClass: "amount" }, [
                  _vm._v(_vm._s(_vm.balances.lastTransaction)),
                ]),
              ]),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm.transactions
      ? _c(
          "div",
          { staticClass: "transactions" },
          [
            _c("p", { staticClass: "transactions-title" }, [
              _vm._v("Recent Transactions"),
            ]),
            _vm._l(_vm.transactions, function (transaction) {
              return _c(
                "a",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.makeTransactionTooltip(transaction),
                      expression: "makeTransactionTooltip(transaction)",
                    },
                  ],
                  key: transaction.hash,
                  staticClass: "transaction-row",
                  attrs: { href: transaction.url },
                },
                [
                  _c("span", { staticClass: "date" }, [
                    _vm._v(_vm._s(transaction.date)),
                  ]),
                  _c(
                    "span",
                    { class: `amount ${transaction.incoming ? "in" : "out"}` },
                    [
                      _vm._v(
                        " " +
                          _vm._s(transaction.incoming ? "+" : "-") +
                          _vm._s(transaction.amount) +
                          " "
                      ),
                    ]
                  ),
                ]
              )
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }